const translationGe = {
  home: "მთავარი",
  about_us: "ჩვენს შესახებ",
  services: "სერვისები",
  air_freight: "საჰაერო",
  land_freight: "სახმელეთო",
  sea_freight: "საზღვაო",
  prices: "ფასები",
  contact: "კონტაქტი",
  log_in: "ავტორიზაცია",
  sign_up: "რეგისტრაცია",
  welcome: "მოგესალმებით",
  password_email_incorrect: "პაროლი ან ელ-ფოსტა არასწორია!",
  transportation_in_any_direction: "ტრანსპორტირება ნებისმიერი მიმართულებით",
  logistics_is_simple_with_us: "ჩვენთან ლოგისტიკა მარტივია",
  email_address: "ელ. ფოსტა",
  password: "პაროლი",
  forgot_password: "დაგავიწყდათ პაროლი ?",
  recover_here: "აღდგენა",
  enter: "შესვლა",
  register: "რეგისტრაცია",
  not_register_yet: "არ ხარ დარეგისტრირებული ?",
  successfully: "მოქმედება დასრულდა წარმატებთ",
  passwords_dont_match: "პაროლები არ ემთხვევა !",
  fill_all_fields: "შეავსეთ ყველა ველი !",
  agree_terms: "რეგისტრაციისთვის დაეთანხმეთ წესებს და პირობებს !",
  email_or_already_registerd:
    "შეამოწმეთ ელ-ფოსტის ვალიდურობა ან მომხმარებელი უკვე დარეგისტრირებულია",
  physical_person: "ფიზიკური პირი",
  legal_entity: "იურიდიული პირი",
  name: "სახელი",
  name_eng: "სახელი ინგლისურად",
  personal_id: "პირადი ნომერი",
  company_id: "კომპანიის ID",
  repeat_password: "გაიმეორეთ პაროლი",
  surname: "გვარი",
  surname_eng: "გვარი ინგლისურად",
  company_name: "კომპანის სახელი",
  company_name_eng: "კომპანიის სახელი ინგლისურად",
  phone: "ტელეფონი",
  password_eight_character: "(8 ან მეტი სიმბოლოსგან)",
  agree_to_our: "დაეთანხმეთ ჩვენს",
  terms_of_use: "პირობებსა",
  and: "და",
  privacy_policy: "კონფიდენციალურობის პოლიტიკას",
  already_have_an_account: "უკვე გაქვთ ანგარიში",
  send: "გაგზავნა",
  got_questions_or_need_more_information:
    "გაქვთ კითხვები ? გჭირდებათ მეტი ინფორმაცია ?",
  fill_in_your_information_and_we_will_get_back_to_you:
    "შეავსეთ ფორმა და დაელოდეთ ჩვენს ზარს",
  error_msg: "დაფიქსირდა შეცდომა!",
  footer_text: `MGL Parcel  ახალი  საამანათო მიმათულებაა MGL Group- ის, რომელიც წარმოადგენს ბულგარულ- ქართულ კომპანიას და 2012 წლიდან ოპერირებს ქართულ ბაზარზე.
    ჩვენ გთავაზობთ ამანათების ტრანსპორტირებას ჩინეთიდან და თურქეთიდან.`,
  frequently_asked_questions: "ხშირად დასმული კითხვები",
  balance: "ბალანსი",
  my_balance: "ჩემი ბალანსი",
  balance_top_up: "ბალანსის შევსება",
  bonus_points: "ბონუს ქულები",
  parcels: "ამანათები",
  my_parcels: "ჩემი ამანათები",
  courier_service: "საკურიერო სერვისი",
  courier: "საკურიერო",
  addresses: "მისამართები",
  flights: "რეისები",
  reports: "მოხსენებები",
  transaction_history: "ტრანზაქციები",
  insurance: "დაზღვევა",
  settings: "პარამეტრები",
  support: "მხარდაჭერა",
  messages: "შეტყობინებები",
  terms_agreement: " პირობები და შეთანხმება მომხმარებლის პოლიტიკა",
  save_changes: "ცვლილებების შენახვა",
  change: "შეცვალე",
  changed: "შეიცვალა",
  go_back: "უკან გასვლა",
  new_password: "ახალი პაროლი",
  repeat_new_password: "გაიმეორე ახალი პაროლი",
  new_email: "ახალი ელექტრონული ფოსტა",
  email_changed: "ელ. ფოსტა შეიცვალა",
  new_phone: "ახალი ტელეფონი",
  message: "შეტყობინება",
  see_more: "მეტის ნახვა",
  my_messages: "ჩემი შეტყობინებები",
  received_messages: "მიღებული შეტყობინება",
  create_new_messages: "ახალი შეტყობინება",
  type_word_here: "ჩაწერეთ სიტყვა...",
  message_details: "შეტყობინების დეტალები",
  subject: "საგანი",
  assistance: "კონსულტაცია",
  transaction_history: "ტრანზაქციების ისტორია",
  date: "თარიღი",
  amount: "თანხა",
  status: "სტატუსი",
  bank: "ბანკი",
  all_fights: "ყველა რეისი",
  land: "სახმელეთო",
  air: "საჰაერო",
  sea: "საზღვაო",
  select_country: "აირჩიე ქვეყანა",
  status: "სტატუსი",
  flight_number: "რეისის ნომერი",
  estimated_arrival_date: "ჩამოსვლის სავარაუდო დრო",
  arrival_date: "ჩამოსვლის თარიღი",
  address: "მისამართი",
  town_city: "ქალაქი",
  state_province: "შტატი / პროვინცია",
  country: "ქვეყანა",
  post_code: "საფოსტო კოდი (ZIP)",
  tracking_number: "თრექინგ ნომერი",
  received: "მიღებული",
  sent: "გამოგზავნილი",
  arrived: "ჩამოსული",
  takeout: "გატანილი",
  weight: "წონა",
  price: "ფასი",
  pay: "გადახდა",
  paid: "გადახდილია",
  details: "დეტალები",
  declare: "დეკლარირება",
  shipment: "რეისი",
  declaration_details: "დეკლარაციის დეტალები",
  deliver_address: "ტრანსპორტირების მისამართი",
  security: "დაზღვევა",
  description: "ნივთის აღწერა",
  website: "ვებ გვერდი",
  currency: "ვალუტა",
  upload_invoice: "ინვოისის ატვირთვა",
  addon_security: "დაზღვევა",
  save: "შენახვა",
  invoice_details: "ინვოისისი დეტალები",
  user_information: "მომხარებლის ინფორმაცია",
  transportation_details: "ტრანსპორტირების დეტალები",
  transportation_price: "ტრანსპორტირების ღირებულება",
  insurance_price: "დაზღვევის ღირებულება",
  total_value: "სრული ღირებულება",
  bank_information: "ბანკის ინფორმაცია",
  account: "ექაუნთი",
  type_amount_here: "მიუთითეთ თანხა",
  choose_method: "გთხოვთ აირჩიეთ სასურველ ჩარიცხვის მეთოდი",
  payment: "ჩარიცხვა",
  amount: "თანხა",
  top_up_my_ballance: "ჩემი ბალანსის შევსება",
  view_bonus_points: "ბონუს ქულების ნახვა",
  action: "მოქმედება",
  charging_amount_from_ballance: "ბალანსიდან თანხის გასვლა",
  kg: "კგ",
  g: "გ",
  pay_with_points: "ქულებით გადახდა",
  warehouse: "საწყობი",
  trustee: "მინდობილი პირი",
  office: "ოფისი",
  trcaution: `მინდობილ პირს შეუძლია ამანათის მიღება ისევე როგორც საკურირო მისამართზე , ასევე მითითებულ ოფისში`,
  transportation: "ტრანსპორტირება",
  about_us_banner_text: `გამოცადეთ სიმარტივე MGL PARCEL-თან ერთად. ათწლეულზე მეტია, ჩვენ გთავაზობთ სანდო, მეგობრულ და უსაფრთხო ტრანსპორტირებას. ამანათები თურქეთიდან და ჩინეთიდან.`,
  about_us_banner_quote: "ჩვენთან ამანათებიც მარტივია.",
  learn_more_about_us: "გაიგეთ მეტი ჩვენს შესახებ",
  ten_year_eperiance: "11 წლიანი გამოცდილება",
  useful_information: "სასარგებლო ინფორმაცია",
  customs_regulations: "საბაჟო წესები",
  client: "კლიენტი",
  street: "ქუჩა",
  terms_and_conditions: "წესები და პირობები",
  minimum_package_weight: "პაკეტის მინიმალური წონა",
  track_parcels: "თვალყური ადევნეთ თქვენს ამანათებს",
  track_parcels_quote: "გაიგეთ ამანათის სტატუსი მისი თრექინგ ნომრის მიხედვით",
  parcel_status: "ამანათის სტატუსი - ",
  data_not_found: "მონაცემები არ მოიძებნა",
  send_new_password: "ახალი პაროლის გამოგზავნა",
  password_sent: "ახალი პაროლი გამოიგზავნა ელ-ფოსტაზე!",
  email_invalid: "ელ-ფოსტა არაა დარეგისტრირებული!",
  do_not_have_insuranced: "თქვენ არ გაქვთ დაზღვეული ამანათი",
  only_georgian: "ტექსტი მიუთითეთ მხოლოდ ქართულად",
  unclear_parcels: "გაურკვეველი ამანათები",
  unlcear_parcels_page_txt:
    "იპოვე შენი ამანთი და გამოაგზავნე შეტყობინება შესაბამის ფოტო მასალასთან ერთად.",
  sent_at: "გამოგზავნის თარიღი",
  terminals_at:
    "ტერმინალური პროცედურების დაწყების თარიღი (საჭიროებს 2-3 სამუშაო დღეს)",
  customs_at:
    "საბაჟო პროცედურების დაწყების თარიღი (საჭიროებს 2-3 სამუშაო დღეს)",
  redistribution_at:
    "ფილიალებში გადანაწილების თარიღი (საჭიროებს 2-3 სამუშაო დღეს)",
  last_updated: "ბოლო განახლება",
  pay_all: "ყველას გადახდა",

  inner_about_us1: `აღმოაჩინეთ MGL Parcel, თქვენი სანდო ლოგისტიკური პარტნიორი დიდი გამოცდილებით და ძლიერი წარმომადგენლობით ევროპაში, საქართველოში და ჩინეთში. ოფისებისა და საწყობების ქსელით, რომლებიც სტრატეგიულად განლაგებულია საკვანძო ადგილებში, ჩვენ გთავაზობთ თქვენი ამანათების უსაფრთხო და ეფექტურ ტრანსპორტირებას.`,
  inner_about_us2: `მომხმარებელზე ორიენტირებული მიდგომით, ჩვენთვის პრიორიტეტია თქვენი კმაყოფილება, სწორედ ამიტომ გთავაზობთ თქვენზე მორგებულ გადაწყვეტილებებს. `,
  inner_about_us3: `ჩვენი გამოცდილი პროფესიონალთა გუნდი ცდილობს უზრუნველყოს განსაკუთრებული მომსახურება და გამოიჩინოს დეტალური ყურადღება ამანათის ტრანსპორტირების პროცესის ყოველ ეტაპზე. ჩვენ გვესმის დროული და უსაფრთხო მიწოდების მნიშვნელობა და ვცდილობთ გადავაჭარბოთ თქვენს მოლოდინს ჩვენი საიმედო და ეკონომიური გადაწყვეტილებებით.`,
  inner_about_us4: `განიცადეთ კომფორტი და სიმშვიდე, რაც მოდის MGL Parcel-თან პარტნიორობით. მოგვანდეთ თქვენი ამანათების ტრანსპორტირება თურქეთიდან და ჩინეთიდან.`,

  our_services: "ჩვენი  სერვისები",
  land_transportation: "სახმელეთო გადაზიდვა",
  kg_constraint: "ამანათის მინიმალური წონა 100 გრამი",
  land_serv1: "იტალია - 3 ევრო -1 კგ / 100 ევრო -1 კუბი",
  land_serv2: "თურქეთი - 2 აშშ დოლარი 1 კგ",
  land_serv3: "ჩინეთი - 5.5 აშშ დოლარი 1 კგ",
  air_transportation: "საჰაერო გადაზიდვა",
  air_serv1: "ჩინეთი - 6.85 - დან  აშშ დოლარი - 1კგ",
  air_serv2: "სპეციალური შეთავაზება + 45 კგ  წონის ტვირთებზე",
  sea_transportation: "საზღვაო გადაზიდვა",
  sea_serv1: "ჩინეთი - 3.5 აშშ დოლარი - 1კგ-დან 100კმ-მდე / 100კგ-დან 3 დოლარი",

  customs_rules_and_laws: "საბაჟო წესები და კანონები",
  customs_rules_and_laws_text:
    "2022 წლის 14 მაისიდან, შეიცვალა საქართველოს მთავრობის №201 დადგენილება. ბოლო ცვლილების მიხედვით, საქართველოს მთავრობა განბაჟების თანხის პარალელურად, აწესებს საქართველოს საბაჟოს მომსახურების გადასახადს განსაბაჟებელ საქონელზე.",
  customs_rules_and_laws_text1:
    "•  300-დან 3 000 ლარამდე ღირებულების საქონელზე განსაბაჟებელ თანხას დაემატება – 20 ლარი;",
  customs_rules_and_laws_text2:
    "•  3 000-დან 10 000 ლარამდე ღირებულების საქონელზე განსაბაჟებელ თანხას დაემატება – 100 ლარი;",
  customs_rules_and_laws_text3:
    "აღნიშნული თანხის გადახდა მოხდება საქართველოს ხაზინის ანგარიშზე (იხ.ანგარიში: 707017021),  დეკლარაციის დაბეჭდვამდე და განბაჟების საფასურის გადახდამდე.",
  customs_rules_and_laws1: "რა შემთხვევაში ექვემდებარება ამანათი განბაჟებას?",
  customs_rules_and_laws1_text:
    "•  საქართველოს კანონმდებლობით, განბაჟებას ექვემდებარება 300 ლარზე მეტი ღირებულების, 30კგ-ზე მეტი წონის ან ერთგვაროვანი საქონელი.",
  customs_rules_and_laws1_text1:
    "•  საბაჟო გადასახადი შეადგენს პროდუქციის ღირებულების და ტრანსპორტირების ჯამის 18%-ს.",
  customs_rules_and_laws2: "რატომ უნდა დაადეკლარიროთ ნივთი ჩამოსვლამდე?",
  customs_rules_and_laws2_text:
    "•  იმ შემთხვევაში, თუ ამანათი არ იქნება დეკლარირებული მისი ჩამოსვლის მომენტისთვის, იგი დაყოვნდება საბაჟოზე  1 სამუშაო კვირა.",
  customs_rules_and_laws2_text1: `•	იმ შემთხვევაში, თუ აღნიშნული ვადის განმავლობაში, არ დაადეკლარირებთ ამანათს  ამანათი ავტომატურად გადაეცემა სახელმწიფოს შემდგომი განკარგვისთვის. 
    გაითვალისწინეთ, თუ მომხმარებლის სახელზე საზღვარს კვეთს რამდენიმე ამანათი, ხოლო დაუდეკლარირებელია მხოლოდ ნაწილი, საბაჟო დაუდეკლარირებელ ამანათებთან ერთად აჩერებს მომხმარებლის დადეკლარირებულ ამანათებსაც. 
    1 კვირის შემდეგ, კანონის მიხედვით ყველა ამანათი ერთად გადადის სახელმწიფოს მფლობელობაში.`,
  customs_rules_and_laws3: "რა დოკუმენტაცია საჭირო ამანათის განსაბაჟებლად?",
  customs_rules_and_laws3_text:
    "•  იმ შემთხვევაში, თუ ამანათი დაექვემდებარა განბაჟებას, მომხმარებელმა ჩვენს ვებ-გვერდზე უნდა ატვირთოს ინვოისი ან შესყიდვის ორდერი, რომელშიც ფიქსირდება შემდეგი სახის ინფორმაცია:",
  customs_rules_and_laws3_text1: "•  მიმღების სახელი",
  customs_rules_and_laws3_text2: "•  მიმღების გვარი",
  customs_rules_and_laws3_text3: "•  ოთახის ნომერი",
  customs_rules_and_laws3_text4: "•  ამანათის შიგთავსი ",
  customs_rules_and_laws3_text5: "•  ამანათის ჯამური ღირებულება.",
  customs_rules_and_laws3_text6: `გაითვალისწინეთ: (1) შემოსავლების სამსახურის ახალი წესების მიხედვით დეკლარირებაში ამანათის მიმღების მიერ არასწორად მითითებული ღირებულების შემთხვევაში გათვალისწინებულია საჯარიმო სანქციები`,
  customs_rules_and_laws4:
    "საქართველოს კანონმდებლობით აკრძალულია საფოსტო გზავნილით შემდეგი სახის ნივთების გამოგზავნა:",
  customs_rules_and_laws4_text: "•  რეალური ფული, მონეტები ან ბანკნოტები;",
  customs_rules_and_laws4_text1:
    "•  საბანკო და საკრედიტო ბარათები და სხვა ფასიანი ქაღალდები;",
  customs_rules_and_laws4_text2: "•  საკვები პროდუქცია;",
  customs_rules_and_laws4_text3: "•  ფეთქებადი ნივთიერებები;",
  customs_rules_and_laws4_text4: "•  მიწა და ნერგები მიწით;",
  customs_rules_and_laws4_text5: "•  პორნოგრაფიული სახის პროდუქცია;",
  customs_rules_and_laws4_text6: "•  ცოცხალი ცხოველები;",
  customs_rules_and_laws4_text7:
    "•  ნებისმიერი სახის ნარკოტიკი, ნარკოტიკული ნივთიერებების შემცველი პროდუქცია;",
  customs_rules_and_laws4_text8:
    "•  ნარკოტიკული ან ფსიქოტროპული ნივთიერებების შემცველი მცენარეები ან მათი თესლი;",
  customs_rules_and_laws4_text9: "•  ფსიქოტროპული ნივთიერებები;",
  customs_rules_and_laws4_text10: "•  წამლები (10 შეკვრაზე მეტი);",
  customs_rules_and_laws4_text11: "•  მცენარეების თესლები (25 გრამზე მეტი);",
  customs_rules_and_laws5:
    "აკრძალული ნივთების სია ქვეყნების და მიმართულების მიხედვით :",
  customs_rules_and_laws5_text: "•  თურქეთი  სახმელეთო - მედიკამენტები.",
  customs_rules_and_laws5_text1: `•  ჩინეთი  ავია - იარაღი, სამიზნე, იარაღის ნაწილები და სხვ, მაგნიტი, სანთელი, სანთებელა (მათ შორის ელექტრო სანთებელა), ფხვნილი, დაპრესილი ფხვნილი, კბილის პასტები, თვალის ჩრდილები, დანა, მაკრატელი და მსგავსი ტიპის ბასრი საგნები, სათამაშო იარაღი, ელექტრო სიგარეტი, ფავერ ბანკი, აკუმულატორი, სითხეები, საღებავი, ფლომასტერი, მცენარეები, სამედიცინო დანიშნულების ტვირთები, ელემენტები (დასაშვებია ელემენტის ჩამოტანა მხოლოდ იმ შემთხვევაში, თუ იგი მოყვება ნივთს. მაგალითად - მობილურს) და  კოსმეტიკური საშუალებები როგორიცაა: მანიკური, აცეტონი, კრემები და სხვა. ფეთქებადსაშიში და აალებადი შეფუთვის მქონე პროდუქცია. მათ შორის: სპრეი, პულვერიზატორი, დეზადორი, აეროზოლი და სუნამო.`,
  customs_rules_and_laws5_text2:
    "•  ჩინეთი საზღვაო - მცენარეები,ფეთქებადსაშიში და აალებადი შეფუთვის მქონე პროდუქცია.იარაღი, სამიზნე, იარაღის ნაწილები და სხვ,",

  panel_insurance_text:
    "MGL PARCEL- ი მომხმარებლებს სთავაზობს ამანათების დაზღვევას საქართველოში გამოგზავნამდე.  და ამით უზრუნველყოს ზარალის სრულად ანაზღაურება ამანათის დაკარგვის ან ტრანსპორტირებისას მისი დაზიანების შემთხვევაში.",
  panel_insurance_text1:
    "გამოწერილი ამანათის დაზღვევა შესაძლებელია მისი მიღების შემდეგ ამანათის საქართველოში გამოგზავნამდე. დაზღვევის მონიშვნის შემთხვევაში ტრანსპორტირების ღირებულებას დაემატება  ამანათის ღირებულების 10 %.",

  თურქეთი: "თურქეთი",
  ჩინეთი: "ჩინეთი",

  code_sent: "კოდი გამოიგზავნა ელექტრონულ ფოსტაზე",
  email_registerd_or_error:
    "მითითებული მეილი უკვე რეგისტრირებულია ან დაფიქსირდა სხვა სახის შეცდომა ცადეთ ხელახლა",
  invalid_code_or_error:
    "მითითებული კოდი არასწორია ან დაფიქსირდა სხვა სახის შეცდომა ცადეთ ხელახლა ",
  email_valid_procc:
    "გთხოვთ გაიაროთ ელ-ფოსტის ვალიდაციის პროცესი გაგრძელებამდე",
  required: "აუცილებელი",

  not_started: "არ დაწყებულა",
  sent: "გზაშია",
  arrived: "ჩამოსულია",
  attach_file: "ფაილის მიმაგრება",
  attached_file: "მიმაგრებული ფაილი",

  denied_items: "აკრძალული ნივთები",

  georgian: "ქართულად",

  personal_parcel: "პერსონალური გზავნილი",
  parcel_amount: "ნივთების რაოდენობა",
  trusted_person: "მინდობილი პირი",

  batumi_poti: "ბათუმი / ფოთი",
  working_hours: "სამუშაო საათები",
  tbilisi: "თბილისი",
  monday_friday: "ორშაბათი - პარასკევი:",
  saturday: "შაბათი:",

  repack: "ამანათის გადაფუთვა",
  repacked: "გადაფუთულია",

  calculate: "გამოთვლა",
  vol_calculator: "მოცულობითი წონის კალკულატორი",
  repack_err:
    "ვერ მოხერხდა ამანათის გადაფუთვა რადგან ამანათის ფასი გამოთვლილია რეალური წონით",
  onlineShop: "ონლაინ მაღაზია",

  declarationPrices: "დეკლარაცია",
  instruction: "ინსტრუქციები",
  calculator: "კალკულატორი",
  information: "ინფორმაცია",

  quotePartOne: "ამანათების გამოწერის",
  quotePartTwo: "ყველაზე",
  quotePartThree: "გზა",
  bannerTextOne: "საცალო მიმართულება",
  bannerTextTwo: "კორპორატიული მიმართულება",
  yearsWithYou: "წელი თქვენთან",
  flight: "რეისი",
  volume: "მოცულობითი",
  workingDay: "სამუშაო დღე",
  calculatorsTitle: "მოცულობითი წონის განმარტება",
  calculatorsTextOne: `მოცულობითი წონა არის სპეციალური საზომი ერთეული, რომელიც გამოიყენება 
  კონკრეტული ნივთის სიმკვრივის დასადგენად.ამ საზომს მნიშვნელობა აქვს საერთაშორისო ავია 
  გადაზიდვების განხორციელების დროს. მოცულობითი წონის გამოთვლის ფორმულაა : სიგრძე 
  ( სმ) x სიგანე ( სმ) x სიმაღლე( სმ) /6000 = მოცულობითი წონა ( კგ) .`,
  instructionsTitle: "როგორ გამოვწეროთ?",
  instructionsText: `გთავაზობთ ვიდეო ინსტრუქციებს თუ როგორ გამოვწეროთ 
  ჩინური ონლაინ მაღაზიებიდან და როგორ დავამატოთ ჩვენი გადამზიდის მისამართი,
   სხვადასხვა სავაჭრო პლატორმებზე. ასევე როგორ დავადეკლალიროთ ჩვენს მიერ 
   გამოწერილი ამანათი`,
  seeAll: "ყველას ნახვა",
  instructions: "ინსტრუქციები",
  noInstructions: "ინსტრუქციები ვერ მოიძებნა",
  logout: "გასვლა",
  height: "სიმაღლე",
  width: "სიგანე",
  length: "სიგრძე",
  giftText: "ყოველ 10 კილოგრამზე 1 კილოგრამი საჩუქრად !",
  easy: "მარტივი",
  reliable: "სანდო",
  codes: "კოდები",
  else: "სხვა",
  empty: "ჩანაწერები ვერ მოიძებნა !",
  registration_successful: "რეგისტრაცია წარმატებულია",
  userCabinet: "მომხმარებლის კაბინეტი",
  fast: "სწრაფი",
  china: "ჩინეთი",
  turkey: "თურქეთი",
  uploadInvoice: "ინვოისი სავალდებულოა",
  airPrice: "6.85$ - დან",

  more_than_250: "პაკეტის ღირებულება აღემატება 250 ლარს",
  more_than_30: "პაკეტის წონა აღემატება 30 კილოს",
  more_than_4: "ნივთების რაოდენობა აღემატება 4-ს",

  blogs: "ბლოგები",
  blogs_not_found: "ბლოგები ვერ მოიძებნა",

  real_weight: "რეალური",
  volume_weight: "მოცულობითი",
};

export default translationGe;

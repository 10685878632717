import { useContext, useState } from "react";
import Button from "../../components/InputComponents/Button";
import Declaration from "./Declaration";
import DeclarationChanged from "./DeclarationChanged";
import AddTrustedPerson from "./AddTrustedPerson";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import { BsAirplane, BsTruck } from "react-icons/bs";
import { FaShip } from "react-icons/fa";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";

const WarehouseCard = ({
  data,
  payPack,
  setUpdatePacks,
  payPackBonusPoints,
}) => {
  const { t } = useTranslation();
  //parcel information popup
  const [detailsShow, setDetailsShow] = useState(false);
  //parcel information popup
  const [decShow, setDecShow] = useState(false);
  //action toggle
  const [toggleActions, setToggleActions] = useState(false);
  //show trusted person popup
  const [trustedShow, setTrustedShow] = useState(false);
  //context
  const { setUpdateUser } = useContext(PrimaryContext);

  const handleRepack = () => {
    if (data.cal_price_by === 1) {
      errorToast(t("repack_err"));
    } else {
      axiosClient
        .post(`/updateWithItems/${data.id}`, {
          repack: data.repack === 1 ? 0 : 1,
        })
        .then((res) => {
          setUpdatePacks(res);
          setUpdateUser(res);
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="p-4 flex items-center md:items-start md:shadow-bs1 md:rounded-lg md:bg-white">
      <div className="w-full grid grid-cols-4 md:grid-cols-2 gap-2">
        <div className="w-full flex flex-row gap-2.5 ">
          <div>
            {data.shipment_type === 1 && (
              <BsTruck className="text-2xl text-teal_600" />
            )}
            {data.shipment_type === 2 && (
              <BsAirplane className="text-2xl text-teal_600" />
            )}
            {data.shipment_type === 3 && (
              <FaShip className="text-2xl text-teal_600" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm md:text-xs text-teal_600 font-normal">
              {data.tracking_number}
            </p>
            <p className="text-xs text-gray-500 font-normal">
              {data.country.country}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center sm:justify-end">
          <div className="flex flex-col gap-1">
            <p className="text-xs md:text-xs text-teal_600 font-semibold">
              {t("real_weight")}:{" "}
              {data?.total_weight
                ? `${data?.total_weight} ${t("kg")}`
                : `0 ${t("kg")}`}
            </p>
            <p className="text-xs md:text-xs text-teal_600 font-semibold">
              {t("volume_weight")}:{" "}
              {data?.volume_weight
                ? `${data?.volume_weight} ${t("kg")} (CBM)`
                : `0 ${t("kg")}`}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center">
          <div className="flex flex-col gap-1">
            <p
              className={`text-sm md:text-xs font-semibold ${
                data.transport_price === data.transport_paid
                  ? "text-teal_600"
                  : "text-red-400"
              }`}
            >
              {data.transport_price.toFixed(2)} GEL
            </p>
            <p className="text-xs text-gray-500 font-normal">{t("price")}</p>
          </div>
        </div>
        <div className="w-full flex items-center">
          <div className="relative w-full">
            <div
              className={`flex gap-2 items-center border-gray_300 border
                            rounded-lg p-2 cursor-pointer justify-between 
                            ${
                              data.packItems.length === 0 ||
                              data.packItems === null
                                ? "bg-red-200"
                                : "bg-white"
                            }`}
              onClick={() => {
                setToggleActions((prev) => !prev);
              }}
            >
              <p className="text-xs text-gray-500 font-normal">{t("action")}</p>
              <BiChevronDown
                className={`transition-all text-teal_600 text-xl ${
                  toggleActions && "rotate-180"
                }`}
              />
            </div>
            {toggleActions && (
              <div
                className="w-full flex gap-2 items-center absolute top-[110%] flex-col border-gray_300 border
                             rounded-lg p-2 z-30 bg-white"
              >
                {data.packItems.length === 0 || data.packItems === null ? (
                  <Button
                    className="cursor-pointer font-normal
                       text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineTeal600_4"
                    onClick={() => {
                      setDecShow(true);
                    }}
                  >
                    {t("declare")}
                  </Button>
                ) : (
                  <Button
                    className="cursor-pointer font-normal
                     text-sm text-center text-cyan_900 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineCyan900_1"
                    onClick={() => {
                      setDetailsShow(true);
                    }}
                  >
                    {t("details")}
                  </Button>
                )}
                <Button
                  className="cursor-pointer font-normal
                       text-sm text-center text-white_A700 w-full"
                  shape="RoundedBorder8"
                  size="unord"
                  variant="OutlineTeal600_4"
                  onClick={() => {
                    setTrustedShow(true);
                  }}
                >
                  {t("trusted_person")}
                </Button>
                {data.transport_price === data.transport_paid ? (
                  <Button
                    className="cursor-pointer font-normal
text-sm text-center text-cyan_900 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineCyan900_1"
                  >
                    {t("paid")}
                  </Button>
                ) : (
                  <Button
                    className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineTeal600_4"
                    onClick={() => {
                      payPack(data.id);
                    }}
                  >
                    {t("pay")}
                  </Button>
                )}
                {data.transport_price !== data.transport_paid && (
                  <Button
                    className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineTeal600_4"
                    onClick={() => {
                      payPackBonusPoints(data.id);
                    }}
                  >
                    {t("pay_with_points")}
                  </Button>
                )}
                {data.shipment_type === 2 && (
                  <Button
                    className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant={
                      data.repack === 1 ? "OutlineTeal600_4" : "OutlineRed500"
                    }
                    onClick={handleRepack}
                  >
                    {data.repack === 1 ? t("repacked") : t("repack")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {detailsShow && (
        <DeclarationChanged
          setDecShow={setDetailsShow}
          data={data}
          setUpdatePacks={setUpdatePacks}
        />
      )}
      {decShow && (
        <Declaration
          setDecShow={setDecShow}
          data={data}
          setUpdatePacks={setUpdatePacks}
        />
      )}
      {trustedShow && (
        <AddTrustedPerson
          setShow={setTrustedShow}
          data={data}
          setUpdatePacks={setUpdatePacks}
        />
      )}
    </div>
  );
};

export default WarehouseCard;
